<template>
  <b-modal
    :id="id"
    title-class="text-center w-100"
    title-tag="h3"
    hide-footer
    size="xl"
  >
    <template #modal-title>
      <code>{{ server.name }}</code>
    </template>
    <div class="d-flex flex-wrap">
      <b-form :style="leftStyle" class="flex-grow-1">
        <b-form-group
          label="Server Alias:"
          label-for="name"
          label-class="m-0"
          class="m-1"
        >
          <b-form-input
            id="name"
            v-model="server.name"
            required
            :disabled="server.isTaken"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Dashboard client API url:"
          label-for="dashboardApiClientUrl"
          label-class="m-0"
          class="m-1"
        >
          <b-form-input
            id="dashboardApiClientUrl"
            type="url"
            v-model="server.dashboardApiClientUrl"
            :disabled="server.isTaken"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Server Provider:"
          label-for="provider"
          label-class="m-0"
          class="m-1"
        >
          <b-form-input
            id="provider"
            v-model="server.provider"
            :disabled="server.isTaken"
          ></b-form-input>
        </b-form-group>

        <div class="rounded border p-2 mb-3 m-1 bg-light">
          <div class="d-flex flex-wrap m-1">
            <b-form-group
              label="Server public hostname:"
              label-for="host"
              label-class="m-0"
              class="m-1 flex-grow-1"
            >
              <b-form-input
                id="host"
                v-model="server.host"
                required
                :disabled="server.isTaken"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Server internal name:"
              label-for="providerServerName"
              label-class="m-0"
              class="m-1"
            >
              <b-form-input
                id="providerServerName"
                v-model="server.providerServerName"
                required
                :disabled="server.isTaken"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="d-flex flex-wrap m-1">
            <b-form-group
              label="Username:"
              label-for="username"
              label-class="m-0"
              class="flex-grow-1 m-1"
            >
              <b-form-input
                id="username"
                v-model="server.userName"
                :disabled="server.isTaken"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Password:"
              label-for="password"
              class="flex-grow-1 m-1"
              label-class="m-0"
            >
              <b-input-group>
                <b-form-input
                  id="password"
                  v-model="server.password"
                  :type="passwordType"
                  :disabled="server.isTaken"
                ></b-form-input>

                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="togglePasswordVisibility"
                    ><b-icon icon="eye" v-if="!passwordVisible" /><b-icon
                      icon="eye-slash"
                      v-if="passwordVisible"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="text-center" v-if="server.hasRemoteDesktop">
            <b-button
              variant="link"
              :href="remoteDesktopUrl"
              :disabled="server.isTaken"
              :download="`${server.name}.rdp`"
            >
              <i class="fas fa-laptop-house mx-1"></i>
              <span class="mx-1">Launch remote desktop</span></b-button
            >
          </div>
        </div>

        <b-form-group
          label="Notes:"
          label-for="notes"
          label-class="m-0"
          class="m-1"
        >
          <vue-editor
            id="notes"
            v-model="server.notes"
            :disabled="server.isTaken"
          ></vue-editor>
        </b-form-group>
      </b-form>

      <div id="sites-and-services" class="flex-grow-1">
        <div class="m-1 pb-2">
          <ServerStatus :server="server" />
        </div>

        <div class="m-1 pb-2" v-if="hasSites">
          <label for="sites" class="mb-0 text-center w-100"
            ><i class="fas fa-pager mx-1"></i>Web sites</label
          >
          <Sites id="sites" :server="server" />
        </div>

        <div class="m-1 pb-2" v-if="hasServices">
          <label for="services" class="mb-0 text-center w-100"
            ><i class="fas fa-cogs mx-1"></i>Observable services</label
          >
          <Services id="services" :server="server" />
        </div>
      </div>
    </div>

    <hr />

    <div v-if="!server.isTaken">
      <div
        v-if="server.id"
        class="d-flex"
        :class="`${server.hasRemoteDesktop ? '' : 'justify-content-between'}`"
      >
        <b-button variant="danger" class="m-1" @click="action('delete')"
          >Delete</b-button
        >

        <b-button
          variant="outline-primary"
          class="m-1"
          @click="action('update')"
        >
          <i class="far fa-edit mx-1"></i>
          <span class="mx-1">Update</span></b-button
        >

        <b-dropdown
          variant="success"
          split
          block
          text="Take server"
          class="m-1 flex-grow-1"
          menu-class="w-100"
          @click="action('take')"
          v-if="server.hasRemoteDesktop"
        >
          <b-dropdown-item @click="action('take-comments')"
            >With comments...</b-dropdown-item
          >
        </b-dropdown>
      </div>

      <div v-if="!server.id">
        <b-button
          block
          variant="success"
          class="m-1 flex-grow-1"
          @click="action('add')"
          >Add server</b-button
        >
      </div>
    </div>

    <div class="d-flex" v-if="server.isTaken">
      <b-button
        v-if="isSelf"
        variant="success"
        class="m-1 flex-grow-1"
        @click="action('release')"
        >Release server</b-button
      >

      <b-button
        v-if="!isSelf"
        variant="warning"
        class="m-1 flex-grow-1"
        @click="action('notify')"
        :disabled="hasNotification"
      >
        <i class="far fa-bell mx-1"></i>
        <span class="mx-1">Receive notification when available</span></b-button
      >

      <b-button
        v-if="!isSelf"
        variant="primary"
        class="m-1 flex-grow-1"
        @click="action('request')"
        title="Sends a notification to the current user requesting the use of the server"
        v-b-tooltip.hover
      >
        <i class="fas fa-bolt mx-1"></i>
        <span class="mx-1">Request server</span></b-button
      >
    </div>
  </b-modal>
</template>

<script>
import Service from "@/service";

import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";

import Sites from "@/components/Sites";
import Services from "@/components/Services";
import ServerStatus from "@/components/ServerStatus";

export default {
  props: {
    id: { type: String },
    server: { type: Object },
    hasNotification: { type: Boolean },
  },

  data() {
    return {
      passwordType: "password",
    };
  },

  components: {
    VueEditor,
    Sites,
    Services,
    ServerStatus,
  },

  computed: {
    ...mapState(["user"]),

    title() {
      return `Manage Server - ${this.server.name}`;
    },

    hasSites() {
      return this.server?.status.sites?.length;
    },

    hasServices() {
      return this.server?.status.services?.length;
    },

    leftStyle() {
      if (this.hasSites || this.hasServices) return "max-width: 50%";
      return "";
    },

    passwordVisible() {
      return this.passwordType == "text";
    },

    isSelf() {
      return this.server.takenById == this.user.id;
    },

    remoteDesktopUrl() {
      return `${Service.serverRdpUrl}/${this.server.id}`;
    },
  },

  methods: {
    togglePasswordVisibility() {
      if (this.passwordVisible) {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },

    action(type) {
      if (type == "delete") {
        if (!confirm("Are you sure?")) return;
      }

      let comments = null;

      if (type == "take-comments") {
        comments = prompt("Write some comments (optional)");
      }

      this.$emit("action", { type, server: this.server, comments });
      this.$bvModal.hide(this.id);
    },
  },
};
</script>