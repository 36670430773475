<template>
  <div
    class="d-flex flex-column server rounded py-3 px-5 clickable server"
    @click="click"
    :title="server.notes"
    v-b-tooltip="{ html: true, trigger: 'hover', customClass: 'tooltip-left' }"
  >
    <div>
      <i class="fas fa-desktop" style="font-size: 500%" :class="css"></i>
    </div>
    <div class="font-weight-bold">
      <i
        class="fas fa-bell text-warning"
        v-if="hasNotification"
        title="You will receive a notification as soon as this server will become available"
        v-b-tooltip.hover
      ></i>
      {{ server.name }}
    </div>

    <div>
      <sup class="text-muted">{{ server.provider }}</sup>
    </div>

    <ServerStatus :server="server" />

    <div v-if="isTaken">
      <div class="text-primary">
        <i class="fas fa-user mr-2"></i>
        <span class="font-weight-bold">{{ server.takenBy }}</span>
        <i
          class="fas fa-comment-alt ml-2 text-warning"
          :title="server.comments"
          v-b-tooltip.html.hover
          v-if="server.comments"
        ></i>
      </div>
      <div>
        <small>{{ taken }}</small>
      </div>
      <div>
        <b-badge pill variant="primary">
          {{ elapsed.hours }}h {{ elapsed.minutes }}m {{ elapsed.seconds }}s
        </b-badge>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils";
import Configuration from "@/configuration";

import ServerStatus from "@/components/ServerStatus";

export default {
  props: {
    server: { type: Object },
    hasNotification: { type: Boolean },
  },

  data() {
    return {
      elapsed: { hours: 0, minutes: 0, seconds: 0, interval: null },
      pulseAnimation: "animate__animated animate__infinite animate__pulse",
    };
  },

  components: {
    ServerStatus,
  },

  computed: {
    css() {
      if (this.server.error) {
        return "text-danger";
      }

      return this.isTaken
        ? `text-warning ${this.pulseAnimation} animate__slow`
        : "";
    },

    taken() {
      return Utils.toDateString(this.server.taken);
    },

    isTaken() {
      return this.server.isTaken;
    },
  },

  watch: {
    isTaken: {
      handler(value) {
        if (value === true) {
          this.elapsed.interval = setInterval(this.updateElapsed, 1000);
        } else {
          if (this.elapsed.interval) {
            clearInterval(this.elapsed.interval);
          }
        }
      },
    },
  },

  methods: {
    click() {
      this.$emit("click", this.server);
    },

    round(value) {
      if (isNaN(value)) return 0;

      return value.toFixed(2);
    },

    updateElapsed() {
      const startTime = new Date(this.server.taken);
      const endTime = new Date();
      let timeDiff = endTime - startTime;

      // strip the ms
      timeDiff /= 1000;

      this.elapsed.seconds = Math.round(timeDiff % 60);

      // remove seconds from the date
      timeDiff = Math.floor(timeDiff / 60);

      this.elapsed.minutes = Math.round(timeDiff % 60);

      // remove minutes from the date
      timeDiff = Math.floor(timeDiff / 60);

      this.elapsed.hours = Math.round(timeDiff % 24);
    },

    getVariant(percent) {
      return Configuration.getPercentVariant(percent);
    },
  },
};
</script>