<template>
  <b-list-group>
    <b-list-group-item
      v-for="service of services"
      :key="service.name"
      class="d-flex justify-content-between align-items-center p-1"
    >
      <div class="small">
        <i
          class="fas fa-cog mx-2"
          :title="service.state"
          :class="getStateClass(service)"
        ></i>
        <span :title="`Process id: ${service.processId}`">
          {{ service.displayName }}</span
        >
      </div>

      <div class="d-flex flex-wrap align-items-center">
        <div class="text-left small mx-2" title="Used memory">
          <i class="fas fa-memory mr-1"></i>
          <span :class="getVariant(service.memory.usedPercent)">
            <span>{{ service.memory.used.toFixed(2) }}MB</span>
            <sub class="font-italic"
              >{{
                (parseFloat(service.memory.usedPercent) || 0).toFixed(2)
              }}%</sub
            ></span
          >
        </div>

        <div class="text-left small mx-2" title="Used CPU">
          <i class="fas fa-microchip mr-1"></i>
          <span>
            <span :class="getVariant(service.cpu.usedPercent)"
              >{{ service.cpu.usedPercent.toFixed(2) }}%</span
            >
          </span>
        </div>

        <b-button size="sm" title="Restart service" variant="primary">
          <i class="fas fa-recycle"></i>
        </b-button>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import Configuration from "@/configuration";

export default {
  props: {
    server: Object,
  },

  computed: {
    services() {
      const result = this.server.status.services;

      result.sort((a, b) => b.memory.used - a.memory.used);

      return result;
    },

    percentLimits() {
      return Configuration.percentLimits;
    },
  },

  methods: {
    getStateClass(service) {
      if (service.state == "Running") return "text-primary";
      return "text-danger";
    },

    getVariant(percent) {
      return `text-${Configuration.getPercentVariant(percent)}`;
    },
  },
};
</script>