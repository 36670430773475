<template>
  <div>
    <div v-if="status.certificate" class="small text-center">
      <i class="fas fa-lock"></i>
      <span class="mx-1 font-weight-bold">SSL expires:</span>
      <span>{{ status.certificate.expirationDate.split(" ")[0] }}</span>
      <b-badge
        pill
        :variant="remainingDaysVariant"
        class="ml-1"
        :class="remainingDaysClass"
      >
        {{ status.certificate.remainingDays }} days
      </b-badge>
    </div>

    <div v-if="server.hasDashboard" class="small m-1 p-1 rounded border">
      <div
        class="m-1 text-primary pb-2 text-center"
        :title="`${os.version} (${os.platform})`"
        v-b-tooltip.hover
      >
        {{ os.name }}
      </div>

      <div class="m-1">
        <div class="text-left">
          <i class="fas fa-microchip mr-1"></i>
          <span>CPU ({{ cpu.cores }} cores)</span>
        </div>
        <b-progress :max="100" :variant="getVariant(cpu.usedPercent)">
          <b-progress-bar
            :value="cpu.usedPercent"
            :label="`${round(cpu.usedPercent)}%`"
          ></b-progress-bar>
        </b-progress>
      </div>

      <div class="m-1">
        <div class="text-left">
          <i class="fas fa-memory mr-1"></i>
          <span>Memory (Total: {{ (memory.total / 1024).toFixed(0) }}GB)</span>
        </div>
        <b-progress :max="100" :variant="getVariant(memory.usedPercent)">
          <b-progress-bar
            :value="memory.usedPercent"
            :label="`${round(memory.usedPercent)}% (${(
              memory.used / 1024
            ).toFixed(0)}GB)`"
          ></b-progress-bar>
        </b-progress>
      </div>

      <div class="m-1" v-for="disk of status.disks" :key="disk.name">
        <div class="text-left">
          <i class="fas fa-hdd mr-1"></i>
          <span
            >Disk {{ disk.label }} (Total:
            {{ (disk.total / 1024).toFixed(0) }}GB)</span
          >
        </div>
        <b-progress :max="100" :variant="getVariant(disk.usedPercent)">
          <b-progress-bar
            :value="disk.usedPercent"
            :label="`${round(disk.usedPercent)}% (${(disk.used / 1024).toFixed(
              0
            )}GB)`"
          ></b-progress-bar>
        </b-progress>
      </div>
    </div>
  </div>
</template>

<script>
import Configuration from "@/configuration";

export default {
  props: {
    server: { type: Object },
  },

  computed: {
    percentLimits() {
      return Configuration.percentLimits;
    },

    status() {
      return this.server.status;
    },

    os() {
      return {
        name: this.status.os?.name,
        platform: this.status.os?.platform,
        version: this.status.os?.version,
      };
    },

    cpu() {
      return {
        cores: this.status.cpu?.cores ?? 0,
        usedPercent: this.status.cpu?.usedPercent ?? 0,
      };
    },

    memory() {
      return {
        total: this.status.memory?.total ?? 0,
        used: this.status.memory?.used ?? 0,
        usedPercent: this.status.memory?.usedPercent ?? 0,
      };
    },

    remainingDaysVariant() {
      const days = this.status.certificate.remainingDays;

      if (days < 7) return "danger";
      if (days < 30) return "warning";

      return "success";
    },

    remainingDaysClass() {
      const days = this.status.certificate.remainingDays;

      if (days < 7) return this.pulseAnimation;

      return "";
    },
  },

  methods: {
    round(value) {
      if (isNaN(value)) return 0;

      return value.toFixed(2);
    },

    getVariant(percent) {
      return Configuration.getPercentVariant(percent);
    },
  },
};
</script>