<template>
  <b-list-group>
    <b-list-group-item
      v-for="site of sites"
      :key="site.name"
      :disabled="site.state == 'Stopped'"
      class="d-flex justify-content-between align-items-center p-1"
    >
      <div class="small">
        <i
          class="fas fa-globe-americas mx-2"
          :title="site.state"
          :class="getStateClass(site)"
        ></i>
        <span :title="`Process id: ${site.processId}`">{{ site.name }}</span>
      </div>

      <div class="d-flex flex-wrap align-items-center">
        <div class="text-left small mx-2" title="Used memory">
          <i class="fas fa-memory mr-1"></i>
          <span :class="getVariant(site.memory.usedPercent)">
            <span>{{ site.memory.used.toFixed(2) }}MB</span>
            <sub class="font-italic"
              >{{ (parseFloat(site.memory.usedPercent) || 0).toFixed(2) }}%</sub
            ></span
          >
        </div>

        <div class="text-left small mx-2" title="Used CPU">
          <i class="fas fa-microchip mr-1"></i>
          <span>
            <span :class="getVariant(site.cpu.usedPercent)"
              >{{ site.cpu.usedPercent.toFixed(2) }}%</span
            >
          </span>
        </div>

        <b-button size="sm" title="Recycle pool" variant="primary">
          <i class="fas fa-recycle"></i>
        </b-button>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import Configuration from "@/configuration";

export default {
  props: {
    server: Object,
  },

  computed: {
    sites() {
      const result = this.server.status.sites;

      result.sort(
        (a, b) =>
          a.state.localeCompare(b.state) || b.memory.used - a.memory.used
      );

      return result;
    },

    percentLimits() {
      return Configuration.percentLimits;
    },
  },

  methods: {
    getStateClass(site) {
      if (site.state == "Started") return "text-primary";
      return "text-danger";
    },

    getVariant(percent) {
      return `text-${Configuration.getPercentVariant(percent)}`;
    },
  },
};
</script>