<template>
  <b-container fluid class="px-0">
    <b-overlay :show="loading" rounded="sm">
      <h1>Servers</h1>

      <div>
        <b-button size="sm" variant="primary" @click="onAddNewClick" class="m-1"
          ><i class="fas fa-plus mx-1"></i
          ><span class="mx-1">Add new server</span></b-button
        >

        <b-button
          size="sm"
          variant="warning"
          class="m-1"
          @click="enableNotifications"
          v-if="!permissions.notifications"
        >
          <i class="far fa-bell mx-1"></i>
          <span class="mx-1">Enable notifications</span></b-button
        >
      </div>

      <div class="m-3 p-2 rounded border bg-light">
        <div class="d-flex flex-wrap justify-content-center">
          <Server
            v-for="server of servers"
            :key="server.id"
            :server="server"
            :class="css.server"
            class="flex-grow-1"
            @click="onClick"
          />
        </div>

        <b-alert
          variant="warning"
          show
          v-if="!servers.length"
          class="flex-grow-1"
          >No servers found</b-alert
        >
      </div>

      <ServerModal
        :id="modalId"
        :server="selectedServer"
        @action="onAction"
        :hasNotification="currentHasNotification"
      />
    </b-overlay>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Service from "@/service";
import Utils from "@/utils";
import Server from "@/components/Server";
import ServerModal from "@/components/ServerModal";

export default {
  data() {
    return {
      css: {
        server: "m-1",
      },
      selectedServerId: {},
      modalId: "server-modal",
      permissions: {
        notifications: Utils.notificationEnabled,
      },
      loading: true,
    };
  },

  computed: {
    ...mapState(["user", "servers", "notifications"]),

    serverNotifications() {
      return this.notifications.filter((x) => x.server != null);
    },

    currentHasNotification() {
      return this.hasNotification(this.selectedServer);
    },

    selectedServer() {
      return this.servers.filter((x) => x.id == this.selectedServerId)[0];
    },
  },

  components: {
    Server,
    ServerModal,
  },

  watch: {
    servers: {
      handler() {
        this.loading = false;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([
      "useServer",
      "releaseServer",
      "updateServer",
      "deleteServer",
      "addServer",
      "addNotification",
    ]),

    onClick(server) {
      this.selectedServerId = server.id;
      this.showModal();
      console.log(server);
    },

    onAddNewClick() {
      this.selectedServerId = -1;
      this.showModal();
    },

    async onAction(e) {
      this.loading = true;

      try {
        switch (e.type) {
          case "add":
            await this.addServer(e.server);
            break;

          case "delete":
            await this.deleteServer(e.server);
            break;

          case "update":
            await this.updateServer(e.server);
            break;

          case "take":
          case "take-comments":
            await this.useServer({
              id: e.server.id,
              userid: this.user.id,
              comments: e.comments,
            });
            break;

          case "release":
            await this.releaseServer(e.server);
            break;

          case "notify":
            await this.receiveNotification(e.server);
            break;

          case "request":
            await this.requestServer(e.server);
            break;
        }
      } finally {
        this.loading = false;
      }
    },

    hasNotification(server) {
      return (
        this.serverNotifications.filter((x) => x.server.id == server.id)
          .length > 0
      );
    },

    async enableNotifications() {
      this.permissions.notifications = await Notification.requestPermission();
    },

    async receiveNotification(server) {
      if (!this.permissions.notifications) {
        await this.enableNotifications();

        if (!this.permissions.notifications) {
          return;
        }
      }

      this.addNotification({ type: "server", value: server });
    },

    async requestServer(server) {
      await Service.requestServer(server);
    },

    showModal() {
      this.$bvModal.show(this.modalId);
    },
  },

  // mounted() {
  //   this.loading = false;
  // },
};
</script>